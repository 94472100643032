.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-button {
  position: relative;

  width: 52px;
  height: 26px;
  margin: 0;

  vertical-align: top;

  background: #ffffff;
  border: 1px solid #bbc1e1;
  border-radius: 15px;
  outline: none;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.toggle-button::after {
  content: '';

  position: absolute;
  left: 3px;
  top: 1.5px;

  width: 20px;
  height: 20px;
  background-color: var(--gray-700);
  border-radius: 50%;

  transform: translateX(0);

  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.toggle-button:checked::after {
  transform: translateX(calc(100% + 3px));
  background-color: #fff;
}

.toggle-button:checked {
  background-color: var(--success);
}
