.pagy-nav {
  @apply flex py-8;
}

.pagy-nav .page a {
  @apply w-6 h-12 flex items-center justify-center;
}
.pagy-nav .page {
  @apply h-12
    w-10
    mr-2
    cursor-pointer
    text-sm
    border
    flex
    items-center
    justify-center
    font-light
    leading-none
    bg-white;

  &.disabled {
    opacity: 0.4;
    @apply cursor-not-allowed;
  }

  &.active,
  &.gap {
    opacity: 0.4;
    @apply cursor-not-allowed;
  }

  &:active,
  &:focus {
    @apply border-primary;
  }

  &.next a,
  &.prev a,
  &.next,
  &.prev {
    @apply w-20;
  }
}

.pagy {
  @apply flex py-8;
  &_link {
    @apply h-6
      w-6
      mr-2
      cursor-pointer
      text-sm
      border
      flex
      items-center
      justify-center
      font-light
      leading-none
      bg-white;

    &.disabled {
      opacity: 0.4;
      @apply cursor-not-allowed pointer-events-none;
    }

    &.active {
      opacity: 0.4;
      @apply cursor-not-allowed pointer-events-none;
    }

    &:active,
    &:focus {
      @apply border-primary;
    }

    &.next a,
    &.prev a,
    &.next,
    &.prev {
      @apply w-20 text-dark;
    }
  }
}

@media screen and (max-width: 470px) {
  .pagy-nav .page {
    @apply w-6
    mr-1.5;

    &.next a,
    &.prev a,
    &.next,
    &.prev {
      @apply w-12;
    }
  }
  .pagy {
    @apply flex py-8;
    &_link {
      @apply w-6
      mr-1.5;
      &.next a,
      &.prev a,
      &.next,
      &.prev {
        @apply w-12 text-dark;
      }
    }
  } 
}