.input {
  @apply py-2
    px-4
    border
    border-gray-500
    leading-tight
    bg-white;

  font-family: inherit;

  .field_with_errors & {
    @apply border-warning;
  }
}
.label {
  @apply block mb-2 text-gray-700 leading-tight;
}

.checkbox {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  @apply appearance-none border w-4 h-4 rounded;
}
