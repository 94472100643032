.filter_modal {
  @apply border-t fixed top-0 left-0 h-screen w-full overflow-y-auto z-20 bg-white;
  -webkit-overflow-scrolling: touch;
  &_payback {
  }

  &_metro {
    @apply pb-32;
  }

  &_district {
    @apply pb-32;
  }

  &_category {
    @apply pb-32;
    .active {
      @apply font-bold;
      &:hover {
        @apply text-gray-700;
      }
    }
  }

  &_btn {
    @apply py-2
      px-6
      rounded
      cursor-pointer
      leading-tight
      no-underline
      appearance-none
      bg-gray-300;
  }
}

.filter_action_mb {
  @apply hidden;
  .full & {
    @apply block;
  }
}
.filter_action {
  @apply hidden;
  .full & {
    @apply block;
  }
}
@screen lg {
  .filter_action_mb {
    @apply block;
  }
  .admin_page__body{
    .filter_modal {
      @apply absolute shadow-md pb-4 px-6;
      top: 100%;
      &_category {
        @apply px-8 h-auto;
        -webkit-overflow-scrolling: touch;
        .full & {
          top: calc(100% - 3rem);
        }
      }
      &_categories {
        @apply px-8 h-auto;
        .full & {
          top: calc(100% - 3rem);
        }
      }
      &_price {
        .full & {
          top: calc(100% - 3rem);
        }
        @apply px-4 h-auto pt-1 w-1/3;
        left: 14%;
      }
      &_realty_area {
        @apply px-4 h-auto pt-1 w-1/3;
        left: 33%;
      }
      &_realty_rent {
        @apply px-4 h-auto pt-1 w-1/3;
        left: 53%;
      }
      &_profit {
        .full & {
          top: calc(100% - 3rem);
        }
        @apply px-4 h-auto pt-1 w-1/3;
        left: 53%;
      }
      &_payback {
        @apply px-0 h-auto pt-0 w-1/4;
        left: 38%;
        .full & {
          top: calc(100% - 3rem);
        }
      }
      &_status {
        @apply px-0 h-auto pt-0 w-1/4;
        left: auto;
        right: 0;
        .full & {
          top: calc(100% - 3rem);
        }
      }
      &_metro {
        @apply px-0 h-auto overflow-scroll pb-20;
      }
      &_district {
        @apply px-8 pt-6 h-auto overflow-scroll pb-20;
      }
  
      &_btn {
        @apply w-full py-2;
      }
    }
  }
  .page__body{
    .filter_modal {
      @apply absolute shadow-md pb-4 px-6;
      top: 100%;
      &_category {
        @apply px-8 h-auto;
        -webkit-overflow-scrolling: touch;
        .full & {
          top: calc(100% - 3rem);
        }
      }
      &_categories {
        @apply px-8 h-auto;
        .full & {
          top: calc(100% - 3rem);
        }
      }
      &_price {
        .full & {
          top: calc(100% - 3rem);
        }
        @apply px-4 h-auto pt-1 w-1/3;
        left: 21%;
      }
      &_realty_area {
        @apply px-4 h-auto pt-1 w-1/3;
        left: 33%;
      }
      &_realty_rent {
        @apply px-4 h-auto pt-1 w-1/3;
        left: 53%;
      }
      &_profit {
        .full & {
          top: calc(100% - 3rem);
        }
        @apply px-4 h-auto pt-1 w-1/3;
        left: 0%;
      }
      &_payback {
        @apply px-0 h-auto pt-0 w-1/4;
        left:50%;
        .full & {
          top: calc(100% - 3rem);
        }
      }
      &_status {
        @apply px-0 h-auto pt-0 w-1/4;
        left: auto;
        right: 0;
        .full & {
          top: calc(100% - 3rem);
        }
      }
      &_metro {
        @apply px-0 h-auto overflow-scroll pb-20;
      }
      &_district {
        @apply px-8 pt-6 h-auto overflow-scroll pb-20;
      }
  
      &_btn {
        @apply w-full py-2;
      }
    }
  }

}

.modal {
  @apply fixed top-0 left-0 h-full w-full overflow-y-auto z-30 flex items-center justify-center;
  &_body {
    @apply z-30;
  }
  &_back {
    @apply absolute top-0 left-0 h-full w-full overflow-y-auto z-20;
    background: rgba(0, 0, 0, 0.6);
  }
}

.count_button {
  @apply whitespace-nowrap;
}
