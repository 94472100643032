.popup {
  transition: 0.2s ease-in-out;
  box-shadow: 0 0 15px rgb(204, 204, 204);
  visibility: visible;
}

.hide_popup {
  transform: translateY(100%);
  visibility: hidden;
}
