.nav {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    overflow: auto;
    transition: .2s;
    @screen lg {
        width: 35%
    }

    &.menu_hidden{
        transform: translateX(100%);   
    }
}