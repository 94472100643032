:root {
  --gray-0: #fff;
  --gray-100: #f6f6f6;
  --gray-200: #edf2f7;
  --gray-300: #e2e8f0;
  --gray-400: #cbd5e0;
  --gray-500: #a0aec0;
  --gray-600: #718096;
  --gray-700: #4a5568;
  --gray-800: #2d3748;
  --gray-900: #1a202c;

  --gray-light: #f8f9fa;

  --white: var(--gray-0);
  --dark: #2c2c2c;

  --main-bg-color: var(--gray-100);

  --text: #2c2c2c;
  --text-muted: var(--gray-500);

  --primary: #ffdd2d;
  --primary-dark: #fcc521;
  --primary-light: #ffe252;

  --secondary: #e2e2e2;
  --secondary-dark: #e4ebf3;

  --success: hsl(141, 40%, 51%);
  --info: hsl(193, 100%, 47%);
  --warning: hsl(36, 100%, 50%);
  --yellow: #ffdd2d;
  --yellow-dark: #fcc521;
  --danger: hsl(360, 99%, 40%);

  --swiper-theme-color: var(--yellow);

  @media (min-width: 600px) {
  }
}
