/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) rgb(255, 255, 255);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 20px;
  border: 0px solid rgb(255, 255, 255);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
